// Generated by Framer (e1877f1)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["B0RcH0Gtx"];

const serializationHash = "framer-s1gxT"

const variantClassNames = {B0RcH0Gtx: "framer-v-1yzp1r7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, image, width, ...props}) => { return {...props, GISUwquSq: image ?? props.GISUwquSq ?? {src: "https://framerusercontent.com/images/ai5aTS5GwmknA02FMovLXZcdo.svg"}} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;image?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GISUwquSq, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "B0RcH0Gtx", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1yzp1r7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"B0RcH0Gtx"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{backgroundColor: "var(--token-3ef2b024-43b5-4c0c-a7b2-ecacd057a93b, rgba(255, 255, 255, 0.02))", ...style}}><Image background={{alt: "", fit: "fit", intrinsicHeight: 35, intrinsicWidth: 133, pixelHeight: 40, pixelWidth: 40, ...toResponsiveImage(GISUwquSq), ...{ positionX: "center", positionY: "center" }}} className={"framer-1cv6lkm"} data-framer-name={"Logo"} layoutDependency={layoutDependency} layoutId={"s8UWkeSDl"}/></motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-s1gxT.framer-1fwj8zq, .framer-s1gxT .framer-1fwj8zq { display: block; }", ".framer-s1gxT.framer-1yzp1r7 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 3px; height: 132px; justify-content: center; overflow: hidden; padding: 14px 28px 14px 28px; position: relative; width: 186px; }", ".framer-s1gxT .framer-1cv6lkm { align-content: center; align-items: center; display: flex; flex: none; flex-direction: column; flex-wrap: nowrap; gap: 0px; height: 71px; justify-content: center; min-width: 130px; overflow: visible; padding: 0px; position: relative; width: min-content; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-s1gxT.framer-1yzp1r7, .framer-s1gxT .framer-1cv6lkm { gap: 0px; } .framer-s1gxT.framer-1yzp1r7 > * { margin: 0px; margin-bottom: calc(3px / 2); margin-top: calc(3px / 2); } .framer-s1gxT.framer-1yzp1r7 > :first-child, .framer-s1gxT .framer-1cv6lkm > :first-child { margin-top: 0px; } .framer-s1gxT.framer-1yzp1r7 > :last-child, .framer-s1gxT .framer-1cv6lkm > :last-child { margin-bottom: 0px; } .framer-s1gxT .framer-1cv6lkm > * { margin: 0px; margin-bottom: calc(0px / 2); margin-top: calc(0px / 2); } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 132
 * @framerIntrinsicWidth 186
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GISUwquSq":"image"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrnBV2gVW1: React.ComponentType<Props> = withCSS(Component, css, "framer-s1gxT") as typeof Component;
export default FramerrnBV2gVW1;

FramerrnBV2gVW1.displayName = "Brands";

FramerrnBV2gVW1.defaultProps = {height: 132, width: 186};

addPropertyControls(FramerrnBV2gVW1, {GISUwquSq: {__defaultAssetReference: "data:framer/asset-reference,ai5aTS5GwmknA02FMovLXZcdo.svg?originalFilename=logoipsum-246.svg&preferredSize=auto", title: "Image", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerrnBV2gVW1, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})